import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import InfoBar from '../../components/Utilities/InfoBar'

class Shots extends React.Component {

  render() {

    const pageTitle = "Shot Conversion"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.shotsPerGoal)[0]).toFixed(2)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.shotsPerGoal)[0]).toFixed(2)
      )
    }

    const comps = [
      {
        "comp": "League + Champs League",
        "mSubStat": subStat('m', 'League and Europe Since 09'),
        "rSubStat": subStat('r', 'League and Europe Since 09'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "League Since 09",
        "mSubStat": subStat('m', 'League Since 09'),
        "rSubStat": subStat('r', 'League Since 09'),
        "btnText": "Season Breakdown",
        "title": "League",
      },
      {
        "comp": "Champions League",
        "mSubStat": subStat('m', 'Europe Since 09'),
        "rSubStat": subStat('r', 'Europe Since 09'),
        "btnText": "Season Breakdown"
      },
    ]

    return (

      <Layout>
        <SEO
          title={`Shooting Stats - Messi vs Ronaldo Career Shots and Shot Conversion`}
          description={`These guys love to shoot, and many of them go in! But how many exactly? Compare Messi vs Ronaldo in terms of shots taken and shot conversion rates.`}
          canonicalPath={`/detailed-stats/shot-conversion/`}
        />

        <h1>Shot Conversion <span className="sr-only">- Messi vs Ronaldo Shots and Shot Conversion</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-4">
          <InfoBar classes="w-full justify-center" msg="Non-European league stats not included in totals" />
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              playerTitleHidden={`${d.comp} shots since 09/10`}
              statType="Shots"
              btnText={d.btnText}
              statView={['Totals', 'Shots Per Goal', 'Per 90 mins', '% Scored']}
              mSubStat={`scores with every ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} shots`}
              rSubStat={`scores with every ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} shots`}
              subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-', 'lowWins')}
              since="09/10"
              title={d.title}
            />
          ))}

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          goals
          apps
          minsPlayed
          shotsPerGoal
          shots
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          goals
          apps
          minsPlayed
          shotsPerGoal
          shots
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          shotsPerGoal
          shots
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          shotsPerGoal
          shots
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          shotsPerGoal
          shots
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          goals
          apps
          minsPlayed
          shotsPerGoal
          shots
        }
      }
    }
  }
`

export default Shots
